import { useMemo } from "react"
import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
  Hr,
} from "@react-email/components"
import * as React from "react"
import { baseUrl } from "./BaseEmailTemplate"
import moment from "moment"
import * as dateServices from "../pages/services/dateServices"
import _ from "lodash"

/**
 * @expiryDate is a formatted date/time string when the 6-digit code expires
 * @returns
 */
export const WorkOrderEmail = ({
  workOrder,
  jobs,
  centres,
  priorities,
  message,
  jobFileUrls,
  useEmbeddedImages = false,
  centreManagers,
}) => {
  const formatDate = (timestampVal) => {
    const dateVal = dateServices.parseDate(timestampVal)
    const formattedDate = moment(dateVal).format("DD/MM/YYYY")
    return formattedDate
  }

  const jobsByCentre = useMemo(() => {
    return _.groupBy(jobs, "centre_id")
  }, [jobs])

  // Get a priority summary, being the priority description, and count of jobs with that priority
  const prioritySummary = useMemo(() => {
    const priorityCounts = _.countBy(jobs, "priority")

    const result = priorities.lookup_values.map((priority) => {
      return {
        ...priority,
        count: priorityCounts[priority.id] || 0,
      }
    })

    console.log("priority summary", result)
    return result
  }, [priorities, jobs])

  return (
    <Html>
      <Head />
      <Preview>Work Order</Preview>
      <Body style={main}>
        <Container style={container}>
          <Section style={logoContainer}>
            <Img
              src={`${baseUrl}/j4j-logo-full-white.png`}
              width="120"
              //height="96"
              alt="Jobs for Joe"
            />
          </Section>
          <Heading style={h1}>Work Order {workOrder.work_order_no}</Heading>

          {message && <Text style={text}>{message}</Text>}

          {/* <Section style={informationTable}>
            <Row>
              <Column style={informationTableColumn}>
                <Heading style={h3}>Bill To</Heading>
                <Text style={text}>a</Text>
                <Text style={text}>b</Text>
                <Text style={text}>c</Text>
              </Column>
              <Column style={informationTableColumn}>
                <Heading style={h3}>Issued By</Heading>
                <Text style={text}>a</Text>
                <Text style={text}>b</Text>
                <Text style={text}>c</Text>
              </Column>
            </Row>
          </Section> */}

          <Hr style={hr} />

          <Section style={informationTable}>
            <Row style={informationTableRow}>
              <Column colSpan={2}>
                <Section>
                  <Row>
                    <Column style={informationTableColumn}>
                      <Text style={informationTableLabel}>Summary</Text>
                      <Text style={informationTableValue}>
                        {workOrder.label}
                      </Text>
                    </Column>
                  </Row>
                  <Row>
                    <Column style={informationTableColumn}>
                      <Text style={informationTableLabel}>Start Date</Text>
                      <Text style={informationTableValue}>
                        {formatDate(workOrder.start_date)}
                      </Text>
                    </Column>
                  </Row>
                  {centres.map((centre) => (
                    <Row key={centre.id}>
                      <Column style={informationTableColumn}>
                        <Text style={informationTableLabel}>
                          {centre.short_name || centre.name} - Job Types
                        </Text>
                        <Text style={informationTableValue}>
                          {Array.from(
                            new Set(
                              jobs
                                .filter((job) => job.centre_id === centre.id)
                                .map((job) => job.category)
                                .filter((item) => item)
                            )
                          ).join(", ")}
                        </Text>
                      </Column>
                    </Row>
                  ))}
                  <Row>
                    <Column style={informationTableColumn}>
                      <Text style={informationTableLabel}>Job Priorities</Text>
                      {prioritySummary
                        .filter((p) => p.count > 0)
                        .map((priority) => (
                          <Text key={priority.id} style={informationTableValue}>
                            {priority.title} - {priority.description} x{" "}
                            {priority.count}
                          </Text>
                        ))}
                    </Column>
                  </Row>
                </Section>
              </Column>
            </Row>
          </Section>

          <Hr style={hr} />

          {centres.map((centre) => (
            <CentreBlock
              key={centre.id}
              centre={centre}
              jobsByCentre={jobsByCentre}
              priorities={priorities}
              jobFileUrls={jobFileUrls}
              useEmbeddedImages={useEmbeddedImages}
              centreManagers={centreManagers}
            />
          ))}

          {/* <Section>
                    <Row style={footerLogos}>
                        <Column style={{ width: "66%" }}>
                            <Img
                                src={`/j4j-logo-full-white.png`}
                                width="120"
                                //height="36"
                                alt="Jobs For Joe"
                            />
                        </Column>
                        <Column>
                            <Row>
                                <Column>
                                    <Link href="/">
                                        <Img
                                            src={`/static/twitter.png`}
                                            width="32"
                                            height="32"
                                            alt="Slack"
                                            style={socialMediaIcon}
                                        />
                                    </Link>
                                </Column>
                                <Column>
                                    <Link href="/">
                                        <Img
                                            src={`/static/facebook.png`}
                                            width="32"
                                            height="32"
                                            alt="Slack"
                                            style={socialMediaIcon}
                                        />
                                    </Link>
                                </Column>
                                <Column>
                                    <Link href="/">
                                        <Img
                                            src={`/static/linkedin.png`}
                                            width="32"
                                            height="32"
                                            alt="Slack"
                                            style={socialMediaIcon}
                                        />
                                    </Link>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Section> */}

          <Section>
            {/* <Link
                        style={footerLink}
                        href="https://jobsforjoe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Our blog
                    </Link>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link
                        style={footerLink}
                        href="https://jobsforjoe.com/legal"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Policies
                    </Link>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link
                        style={footerLink}
                        href="https://jobsforjoe.com/help"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Help center
                    </Link>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link
                        style={footerLink}
                        href="https://jobsforjoe.com/community"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-auth="NotApplicable"
                        data-linkindex="6"
                    >
                        Jobs For Joe Community
                    </Link> */}
            <Text style={footerText}>
              ©2024 Jobs For Joe Pty Ltd. <br />
              {/* /// Address here.... <br />
                        <br /> */}
              All rights reserved.
            </Text>
          </Section>
        </Container>
      </Body>
    </Html>
  )
}

const CentreBlock = ({
  centre,
  jobsByCentre,
  priorities,
  jobFileUrls,
  useEmbeddedImages,
  centreManagers,
}) => {
  const jobs = jobsByCentre[centre.id]

  const cityStatePostcode = [centre.city, centre.state, centre.postcode].filter(
    (item) => item
  )

  const centreManager = centreManagers.find(
    (cm) => cm.id === centre.user_id_contact
  )

  const centreContact = [
    centreManager?.name ||
      (centreManager
        ? `${centreManager?.first_name} ${centreManager?.lastName}`
        : ""),
    centreManager?.phone,
    centreManager?.email,
  ].filter((item) => item)

  const addrElements = [
    centre.addr1,
    centre.addr2,
    cityStatePostcode.join(" "),
    centre.phone,
    centreContact.join(" | "),
  ].filter((el) => el !== null && el !== "")

  const jobIds = jobs.map((job) => job.id)

  const jobUrls = jobFileUrls.filter((jobFileUrl) =>
    jobIds.includes(jobFileUrl.job_id)
  )

  return (
    <Section style={{ marginLeft: "10px" }}>
      <Heading style={h3}>{centre.name}</Heading>
      {addrElements.map((el) => (
        <Text key={el} style={text}>
          {el}
        </Text>
      ))}
      <Link style={urlLink}>{centre.email}</Link>
      <Section style={jobsBlock}>
        {jobs.map((job, index) => (
          <Section key={job.id}>
            <Hr style={hr} />
            <JobBlock job={job} priorities={priorities} centre={centre} />
            <JobImages
              jobUrls={jobUrls.filter((jobUrl) => jobUrl.job_id === job.id)}
              useEmbeddedImages={useEmbeddedImages}
            />
          </Section>
        ))}
      </Section>
    </Section>
  )
}

const JobImages = ({ jobUrls, useEmbeddedImages }) => {
  return (
    <Section>
      {jobUrls.map((jobUrl) => (
        <Row key={jobUrl.fileName}>
          <Section
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <Img
              width="300"
              src={useEmbeddedImages ? `cid:${jobUrl.fileName}` : jobUrl.url}
            />
          </Section>
        </Row>
      ))}
    </Section>
  )
}

const JobBlock = ({ job, priorities, centre }) => {
  const priority = priorities.lookup_values.find((p) => p.id === job.priority)

  return (
    <Section style={informationTable}>
      <Row style={informationTableRow}>
        <Column>
          <Section>
            <Row>
              <Column style={informationTableColumn}>
                <Text style={informationTableLabel}>Job</Text>
                <Link
                  style={urlLink}
                  href={`https://jobsforjoe.com/#/jobedit/${job.id}`}
                >
                  {job.label}
                </Link>
              </Column>
            </Row>
            <Row>
              <Column style={informationTableColumn}>
                <Text style={informationTableLabel}>Location</Text>
                <Text style={informationTableValue}>
                  {job.location} at {centre.short_name || centre.name}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column style={informationTableColumn}>
                <Text style={informationTableLabel}>Category</Text>
                <Text style={informationTableValue}>
                  {job.category || "Not set"}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column style={informationTableColumn}>
                <Text style={informationTableLabel}>Priority</Text>
                <Text style={informationTableValue}>
                  {priority
                    ? `${priority.title} - ${priority.description}`
                    : "Not set"}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column style={informationTableColumn}>
                <Text style={informationTableLabel}>Description</Text>
                <Text style={informationTableValue}>
                  {job.description || "No further description"}
                </Text>
              </Column>
            </Row>
          </Section>
        </Column>
      </Row>
    </Section>
  )
}

export default WorkOrderEmail

const footerText = {
  fontSize: "12px",
  color: "#b7b7b7",
  lineHeight: "15px",
  textAlign: "left",
  marginBottom: "50px",
}

const footerLink = {
  color: "#b7b7b7",
  textDecoration: "underline",
}

const footerLogos = {
  marginBottom: "32px",
  paddingLeft: "8px",
  paddingRight: "8px",
  width: "100%",
}

const socialMediaIcon = {
  display: "inline",
  marginLeft: "32px",
}

const main = {
  backgroundColor: "#ffffff",
  margin: "0 auto",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
}

const container = {
  maxWidth: "600px",
  margin: "0 auto",
}

const logoContainer = {
  marginTop: "32px",
}

const h1 = {
  color: "#1d1c1d",
  fontSize: "36px",
  fontWeight: "700",
  margin: "30px 0",
  padding: "0",
  lineHeight: "42px",
}

const jobsBlock = {
  margin: "15px 0",
  padding: "0",
  lineHeight: 1.4,
}

const h3 = {
  color: "#1d1c1d",
  fontSize: "16px",
  fontWeight: "700",
  margin: "10px 0",
  padding: "10px 0",
  lineHeight: "5px",
}

const heroText = {
  fontSize: "20px",
  lineHeight: "28px",
  marginBottom: "30px",
}

const codeBox = {
  background: "rgb(245, 244, 245)",
  borderRadius: "4px",
  marginRight: "50px",
  marginBottom: "30px",
  padding: "43px 23px",
}

const confirmationCodeText = {
  fontSize: "30px",
  textAlign: "center",
  verticalAlign: "middle",
}

const text = {
  color: "#000",
  fontSize: "16px",
  lineHeight: "1.1",
  padding: 0,
  margin: "5px 0px",
}

const informationTable = {
  borderCollapse: "collapse",
  borderSpacing: "0px",
  color: "rgb(51,51,51)",
  backgroundColor: "rgb(250,250,250)",
  borderRadius: "3px",
  fontSize: "14px",
  marginBottom: "20px",
}

const informationTableRow = {
  height: "60px",
}

const informationTableColumn = {
  paddingLeft: "10px",
  borderStyle: "solid",
  borderColor: "white",
  borderWidth: "0px 1px 1px 0px",
  height: "50px",
}

const resetText = {
  margin: "0",
  padding: "0",
  lineHeight: 1.4,
}

const informationTableLabel = {
  ...resetText,
  color: "rgb(102,102,102)",
  fontSize: "12px",
}

const informationTableValue = {
  fontSize: "16px",
  margin: "0",
  padding: "0",
  lineHeight: 1.4,
}

const hr = {
  borderColor: "#cccccc",
  margin: "20px 0",
}

const urlLink = {
  color: "#1155cc",
  textDecoration: "underline",
  cursor: "pointer",
  fontSize: "16px",
}

const imgResponsive = {
  width: "300px" /* Maximum width of the image */,
  height: "auto" /* Height scales proportionally */,
  display: "block" /* Optional: removes additional space below the image */,
  margin: "10px 20px",
}
