import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components"
import * as React from "react"

const baseUrl = `https://jobsforjoe.com`

export const BaseEmailTemplate = (props) => {
  return (
    <Html>
      <Head />
      <Preview>Confirm your email address</Preview>
      <Body style={main}>
        <Container style={container}>
          <Section style={logoContainer}>
            <Img
              src={`${baseUrl}/j4j-logo-full-white.png`}
              width="120"
              //height="96"
              alt="Jobs for Joe"
            />
          </Section>
          {props.children}

          <Section>
            <Row style={footerLogos}>
              <Column style={{ width: "66%" }}>
                <Img
                  src={`${baseUrl}/j4j-logo-full-white.png`}
                  width="120"
                  //height="36"
                  alt="Jobs For Joe"
                />
              </Column>
              <Column>
                <Row>
                  <Column>
                    <Link href="/">
                      <Img
                        src={`${baseUrl}/static/twitter.png`}
                        width="32"
                        height="32"
                        alt="Twitter"
                        style={socialMediaIcon}
                      />
                    </Link>
                  </Column>
                  <Column>
                    <Link href="/">
                      <Img
                        src={`${baseUrl}/static/facebook.png`}
                        width="32"
                        height="32"
                        alt="Facebook"
                        style={socialMediaIcon}
                      />
                    </Link>
                  </Column>
                  <Column>
                    <Link href="/">
                      <Img
                        src={`${baseUrl}/static/linkedin.png`}
                        width="32"
                        height="32"
                        alt="LinkedIn"
                        style={socialMediaIcon}
                      />
                    </Link>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Section>

          <Section>
            <Link
              style={footerLink}
              href="https://jobsforjoe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our blog
            </Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link
              style={footerLink}
              href="https://jobsforjoe.com/legal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Policies
            </Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link
              style={footerLink}
              href="https://jobsforjoe.com/help"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help center
            </Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link
              style={footerLink}
              href="https://jobsforjoe.com/community"
              target="_blank"
              rel="noopener noreferrer"
              data-auth="NotApplicable"
              data-linkindex="6"
            >
              Jobs For Joe Community
            </Link>
            <Text style={footerText}>
              ©2024 Jobs For Joe Pty Ltd. <br />
              /// Address here.... <br />
              <br />
              All rights reserved.
            </Text>
          </Section>
        </Container>
      </Body>
    </Html>
  )
}

const footerText = {
  fontSize: "12px",
  color: "#b7b7b7",
  lineHeight: "15px",
  textAlign: "left",
  marginBottom: "50px",
}

const footerLink = {
  color: "#b7b7b7",
  textDecoration: "underline",
}

const footerLogos = {
  marginBottom: "32px",
  paddingLeft: "8px",
  paddingRight: "8px",
  width: "100%",
}

const socialMediaIcon = {
  display: "inline",
  marginLeft: "32px",
}

const main = {
  backgroundColor: "#ffffff",
  margin: "0 auto",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
}

const container = {
  maxWidth: "600px",
  margin: "0 auto",
}

const logoContainer = {
  marginTop: "32px",
}

const h1 = {
  color: "#1d1c1d",
  fontSize: "20px",
  fontWeight: "700",
  margin: "10px 0",
  padding: "0",
  lineHeight: "20px",
}

const h2 = {
  color: "#222",
  fontSize: "16px",
  fontWeight: "700",
  margin: "10px 0",
  padding: "0",
  lineHeight: "8px",
}

const labelText = {
  color: "#aaa",
  fontSize: "12px",
}

const heroText = {
  fontSize: "16px",
  lineHeight: "28px",
  marginBottom: "30px",
}

const codeBox = {
  background: "rgb(245, 244, 245)",
  borderRadius: "4px",
  marginRight: "50px",
  marginBottom: "30px",
  padding: "43px 23px",
}

const confirmationCodeText = {
  fontSize: "30px",
  textAlign: "center",
  verticalAlign: "middle",
}

const text = {
  color: "#000",
  fontSize: "12px",
  lineHeight: "24px",
}

export default BaseEmailTemplate
export { h1, h2, heroText, text, baseUrl, labelText }
